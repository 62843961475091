body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#logo {
  height: 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.word-break {
  word-break: break-all;
}

.spinner {
  color: #5f5da2;
}

#closeBtn {
  color: #fff;
}

#eduFontSize {
  font-size: "16px";
}

#dropdown-item {
  position: relative;
  min-width: 237px;
  z-index: 1;
}

#paid {
  background-color: #5f5da2;
  float: right;
}

.image {
  position: relative;
}

.descVal {
  font-size: 13px;
  color: #da5753;
}

.modalScroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

#cnclBtn {
  background-color: #dedede;
  color: #d9586d;
  border: none;
}

#prev-button {
  background-color: #dedede;
  color: #d9586d;
  border: none;
}

#next-button {
  background-color: #5f5da2;
  color: #fff;
  border: none;
}

#cardImg {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-top: -64px;
}

#cardFooter {
  background-color: transparent;
  border: 0;
}

.modalStyle {
  background-color: #5f5da2;
  color: #fdfdfe;
}

#link {
  display: block;
  color: black;
  text-decoration: none;
}

#modalImage {
  height: 150px;
}

#templateButton {
  background-color: transparent;
  color: black;
  border: 0px;
}

.homepage {
  max-height: calc(100vh - 64px);;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 68px;
  background-color: #f2f2f2;
}

.max-width {
  max-width: 1200px;
}

.intTagInput {
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 5px;
}

#badge {
  background-color: #5f5da2;
}

.subDiv {
  height: -webkit-fill-available;
}

.homeDiv {
  max-height: calc(100vh + 10vh);
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-top: 68px; */
  background-color: #f2f2f2;
}

#saveBtn {
  background-color: #5f5da2;
  border: none;
  font-weight: 500;
}

.editbtnAbt {
  font-size: 84%;
}

#row {
  background-color: #f2f2f2;
  margin: 0 !important;
}

#profileDiv {
  font-size: 120%;
  margin-right: -4%;
}

#navbarDropdownMenuLink {
  background: #5f5da2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.navbar-toggler .navbar-toggler-icon {
  width: 32px;
  height: 17px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

div::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

div::-webkit-scrollbar-thumb {
  background-color: #5f5da2;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
}

#btn-logout {
  background: rgba(158, 158, 158, 0.2);
  color: #3c3c3c;
  border-color: rgba(158, 158, 158, 0.2);
}

#downloadButton {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  color: rgb(95, 93, 162);
}

i.createNewButton {
  color: #5f5da2;
}

.__react_modal_image__modal_container {
  z-index: 1500 !important;
}

.modalImage {
  height: 150px !important;
  width: 150px;
}

.tempCard {
  text-transform: capitalize;
}

#btn-Buy {
  background-color: #5f5da2;
}

ol.progtrckr {
  text-align: center !important;
}

ol.progtrckr li.progtrckr-done {
  border-bottom: 4px solid #5f5da2 !important;
}

ol.progtrckr li:before {
  bottom: -4rem !important;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713" !important;
  font-size: 14px !important;
  padding: 2px !important;
  color: white !important;
  background-color: #5f5da2 !important;
  line-height: 1.2em;
  border-radius: 50% 50% !important;
  width: 20px !important;
}

#loginPagePadding {
  padding-top: 4%;
}

.custom-tick-color {
  color: #5f5da2 !important;
}

.custom-price {
  padding-top: 10% !important;
  padding-left: 15px;
  padding-right: 15px;
}

#loginPage {
  margin: 0px !important;
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh + 9vh);
}

#cardHeader {
  background-color: white !important;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #5f5da2;
  background: linear-gradient(#5f5da2 0%, #5f5da2 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #5f5da2;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5f5da2;
}

.custom-nav {
  border-bottom: 1px solid #d9d8ec;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #5f5da2;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5f5da2;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon.custom-ribbon {
  right: 10px !important;
  top: -4px !important;
}

.custom-price-width {
  min-width: 75%;
}

@media screen and (max-width: 991px) {
  #navbarSupportedContent {
    width: 250px;
    max-width: 250px;
    position: absolute;
    right: 1px;
    top: 68px;
    background: #fff;
    padding: 8px;
    box-shadow: 1px 1px 1px 1px #d1cdcd;
  }
  .custom-price-width {
    min-width: 95% !important;
  }
}

@media screen and (max-width: 576px) {
  #loginPagePadding>div {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 400px) {
  #navbarSupportedContent {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    top: 68px;
    background: #fff;
    box-shadow: 1px 1px 1px 1px #d1cdcd;
  }
  .custom-fixed-top {
    position: initial !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}

.title {
  margin-top: 3px;
  padding: 10px;
}

.text-container {
  max-height: calc(100vh + 9vh);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #5f5da2;
  height: -webkit-fill-available;
  text-align: center;
}

.four {
  font-size: 164px;
  padding: 10px;
}

.notFound {
  font-size: 100px;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.ErrorImg {
  padding-bottom: 61px;
}

.not-found-text {
  font-size: 30px;
  margin-top: 0;
  text-align: center;
}

.stu-logo {
  width: 256px;
  margin-top: 128px;
  margin-bottom: 30px;
}

.back-btn-container {
  width: 75px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 18px 9px;
  box-shadow: 1px 1px 12px 0px #525252;
  background-color: #fff;
  cursor: pointer;
  /* text-align: center; */
}

.back-btn {
  text-decoration: none;
  color: black;
}

.loadingPage {
  text-align: center;
}

.loadingSpinner {
  padding-left: 36%;
}

.registrationPendingPAge {
  padding-top: 40px;
}

.successPage {
  padding-top: 30px;
}

#submitBtn {
  width: 300px;
  background-color: #5f5da2;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.google {
  width: 100%;
  height: 36px;
  justify-content: center;
  color: #fff !important;
  margin-top: 6px;
  background-color: #e15242 !important;
}

.linkedin {
  width: 100%;
  height: 36px;
  justify-content: center;
  cursor: pointer !important;
  color: #fff !important;
  margin-top: 6px;
  font-weight: 500;
  font-size: 14px;
  background-color: #0084bf !important;
  display: inline-flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-family: Roboto, sans-serif;
}

.kep-login-facebook.small {
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 6px;
}

.shape-left {
  height: 1px;
  margin-top: 12px;
  width: 60px;
  background-color: #756af8;
}

.shape-right {
  height: 1px;
  margin-top: 12px;
  width: 60px;
  background-color: #756af8;
}

.or {
  color: #5f5da2;
  font-size: 15px;
  font-weight: 600;
}

.titleErrorMsg {
  color: #e15242;
}

.payment-types li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px !important;
}

.payment-types img {
  max-width: 150px
}
/* .payment-types button{
  border: 1px solid #007bff;
    width: 75px;
    color: #007bff;
} */
.cf-outer-div{
  z-index: 1100 !important;
}